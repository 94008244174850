<template>
  <div>
    <FormArticle
      :initial-form-data="article"
      :create="article => $store.dispatch('articles/create', article)"
      :update="article => $store.dispatch('articles/update', article)"
    />
  </div>
</template>
<script lang="ts">
import Vue from 'vue';
import FormArticle from "@/components/organisms/FormArticle.vue";

export default Vue.extend({
  components: {
    FormArticle
  },
  computed: {
    article(){
      return this.$store.getters['articles/byId'](this.$route.params.articleId)
    }
  },
  mounted(){
    if(this.$route.params.articleId){
      this.$store.dispatch('articles/read', this.$route.params.articleId)
    }
  }
});
</script>
<style scoped lang="less">
@import '../less/variables';
@import '../less/mixins';



</style>
