<template>
  <AppPage class="centered">
    <div style="width: 100%;">
      <div class="container">
        <div class="input-group">
          <label>Title</label>
          <InputText :value="formData.title" name="title" placeholder="Title" />
        </div>
        <div class="input-group">
          <label>Slug</label>
          <InputText :value="formData.slug" name="slug" placeholder="slug" />
        </div>
        <div class="input-group">
          <label>Category</label>
          <InputText :value="formData.category" name="category" placeholder="Category" />
        </div>
        <div class="input-group">
          <label>Body</label>
          <InputTextArea :value="formData.body" name="body" placeholder="Body" />
        </div>

        <VueEditor v-model="body" />

        <div class="input-group">
          <label>Meta Description</label>
          <InputTextArea :value="formData.metaDescription" name="metaDescription" placeholder="Meta Description" />
        </div>
        <div class="input-group">
          <label>Category</label>
          <InputSelect :value="formData.tags" name="tags" :options="blogCategoryOptions" :multiple="true"/>
        </div>
        <div class="input-group">
          <label>Author</label>
          <InputText :value="formData.author" name="author" placeholder="Author" />
        </div>
        <div class="input-group">
          <label>Published At</label>
          <InputText :value="formData.publishedAt" name="publishedAt" placeholder="Published At" />
        </div>
        <Button @click="validateAndSubmit" class="primary">Submit</Button>
      </div>
      <div class="article">
        <header>
          <div class="app-width">
            <div class="tags">
              <span class="tag">
                {{formData.category}}
              </span>
              <span v-for="tag in formData.tags" :key="tag" class="tag">
                {{tag}}
              </span>
            </div>
            <h1>{{formData.title}}</h1>
            <span class="byline">
              By <router-link to="/blog">{{formData.author}}</router-link> on {{ formatDate }}
            </span>
          </div>
        </header>
        <main v-html="formData.body"/>
      </div>
    </div>
  </AppPage>
</template>
<script lang="ts">
import Vue from 'vue';
import MixinForm from "@/components/MixinForm.vue";
import AppPage from "@/components/organisms/AppPage.vue";
import { VueEditor } from "vue2-editor";

export default Vue.extend({
  components: {
    AppPage,
    VueEditor
  },
  mixins: [MixinForm],
  data(){
    return {
      body: 'whats up',
      blogCategoryOptions: [
        'News',
        'Whatever'
      ],
    }
  },
  computed: {
    formatDate(){
      return new Date(this.formData.publishedAt).toLocaleDateString();
    }
  },
  watch:{
    // body: {
    //   handler(){
    //     this.setFieldValue('body', this.body);
    //   },
    //   immediate: true
    // },
    initialFormData: {
      handler(){
        if(this.formData.body){
          this.body = this.formData.body;
        }
      },
      immediate: true
    }
  },
  mounted(){
    this.setFieldValue('author', 'StoryFolder');
    this.setFieldValue('publishedAt', new Date().toString());
    if(this.formData._id){
      this.$store.dispatch('articles/read', this.formData._id);
    }
  }
});
</script>
<style scoped lang="less">
@import '../../less/variables';
@import '../../less/mixins';
@import '../../less/form-layout';

.container{
  width: 800px;
  margin:0 auto;
  padding-top: 200px;
}

.app-width{
  width: 800px;
  margin-right: auto;
  margin-left: auto;
}
.read-width(){
  max-width: 700px;
  margin-right: auto;
  margin-left: auto;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

header{
  background: @black;
  height: 30vw;
  min-height: 500px;
  box-sizing: border-box;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 2em;
  .tags{
    display: flex;
    gap: 0.75em;
    align-items: center;
    justify-content: center;
  }
  .tag{
    background: @purpleVibrant;
    color: @white;
    display: inline-block;
    padding: 0.15em 0.6em;
    font-size: 80%;
    text-transform: uppercase;
  }
  h1{
    margin: 0.25em 0 0.5em 0;
    font-weight: 800;
  }
}
.article{
  padding-top: 100px;
  background: @white;
  font-weight: 500;
}

main{
  line-height: 2em;
}
/deep/ p{
  font-size: 95%;
  margin-bottom: 1.25em;
  color: @blackSoft;
  font-weight: 500;
  font-family: "Merriweather", serif;
  .read-width();
}

/deep/ h2{
  .read-width();
  font-size: 0.85em;
  text-transform: uppercase;
  color: @purpleLight;
  font-weight: 700;
}
/deep/ h3{
  .read-width();
  color: @blackSoft;
  font-weight: 600;
  margin-bottom: 0.75em;
  letter-spacing: -0.01em;
}
/deep/ h4, /deep/ h5, /deep/ h6{
  .read-width();
  color: @blackSoft;
  margin-bottom: 0.5em;
}

/deep/ li {
  padding: 0;
  margin: 0;
  font-size: 0.9em;
}


/deep/ section{
  margin: 10vh 0;
  &.centered{
    text-align: center;
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 5vh;
    padding-bottom: 5vh;
    &.dark{
      background: @purpleDark;
      p, h2{
        color: @white;
      }
    }
    h2{
      font-size: 2.5rem;
      text-transform: unset;
      margin-top: 0.5em;
      margin-bottom: 0.5em;
    }
    iframe{
      margin-top: 1em;
      width: 80vw;
      max-width: 1000px;
    }
  }
  &.colorblock{
    background: @purpleLight;
    color: @white;
    margin-top: 0;
    margin-bottom: 0;
    .read-width();
    padding: 3em 4em;
    box-sizing: border-box;
    h3{
      color: @white;
    }
    h2{
      color: fade(@white, 50%);
    }
    p, li{
      color: @white;
    }
  }
  &.colorblock + .colorblock{
    background: darken(@purpleLight, 10%);
  }
  &.indent{
    .read-width();
    box-sizing: border-box;
    display: flex;
    background: @white;
    box-shadow: 0.25em 0.25em 1.5em fade(@black, 15%);
    padding: 2em 3em;
    align-items: flex-start;
  }
}
</style>
